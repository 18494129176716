import React from "react";
import { motion } from "framer-motion"
import './App.css';

function App() {
  const line1 = "Software Freedom Day 2022"
  const line2 = "Coming Soon"
  const sentence = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.5,
        staggerChildren: 0.08,
      },
    },
  }
  const letter = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
    },
  }
  return (
    <div className="App">
      <div className="main">
        <motion.h1
          className="load-screen--message"
          variants={sentence}
          initial="hidden"
          animate="visible">
            {line1.split("").map((char, index) => {
              return (
                <motion.span key={char + "-" + index} variants={letter}>
                  {char}
                </motion.span>
              )
            })}
        </motion.h1>
        <div className="coming-soon">
          <motion.h3
            className="load-screen--message"
            variants={sentence}
            initial="hidden"
            animate="visible">
              {line2.split("").map((char, index) => {
                return (
                  <motion.span key={char + "-" + index} variants={letter}>
                    {char}
                  </motion.span>
                )
              })}
          </motion.h3>
        </div>
      </div>
    </div>
  );
}

export default App;
